// @mui
// import Box from '@mui/material/Box';
import React, { useEffect, useMemo } from 'react';
// import Slider from 'react-slick';
// redux
import { dispatch } from 'src/redux/store';
import { getHomePageProducts } from 'src/redux/slices/product';
import { useSelector } from 'react-redux';
// sections
import ProductList from 'src/sections/product/product-list';
import { AdBanner } from 'src/components/ad-banner';
import HomeLandingHero from 'src/sections/home/home-landing-hero';
import HomeSearch from 'src/sections/home/home-search';
import HomeCategories from 'src/sections/home/home-categories';

// lazy
// const PollCompLazy = React.lazy(() => import('src/sections/poll/view/PollCard'));

// ----------------------------------------------------------------------

export default function HomeView() {
  const { currentUser } = useSelector((state) => state.auth);

  const {
    featuredProducts,
    bannerProducts,
    newArrivalsProducts,
    nearestProducts,
    categoryCounts,
    isLoading,
  } = useSelector((state) => state.product);

  const memoizedProducts = useMemo(
    () => ({
      featuredProducts,
      bannerProducts,
      newArrivalsProducts,
      nearestProducts,
      categoryCounts,
    }),
    [featuredProducts, bannerProducts, newArrivalsProducts, nearestProducts, categoryCounts]
  );

  useEffect(() => {
    if (!memoizedProducts.newArrivalsProducts?.length) {
      dispatch(getHomePageProducts({ location: currentUser?.location }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const settings = {
  //   vertical: true,
  //   verticalSwiping: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   dots: false,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   speed: 500,
  // };

  return (
    <>
      <HomeSearch />

      {/* <Slider {...settings}> */}
      {/* <AdBanner
          title="Celebrate Eid al-Fitr with Us!"
          description="Get your Eid essentials and exclusive offers just in time for the celebration. Don’t miss out!"
          ctaText="Shop Now"
          info="Eid al-Fitr is just around the corner, and we're here to help you prepare! Explore amazing deals on traditional clothing, delicious sweets, home decor, gifts, and more. Whether you're celebrating with family or sending love from afar, make this Eid memorable with everything you need in one place. Shop early and enjoy exclusive offers!"
        /> */}
      <AdBanner
        title="Advertise Duration Extended!"
        description="We've extended the ad duration from 31 to 60 days. Post your ad now!"
        ctaText="Learn More"
        info="Starting January 8th, 2025, your ads will stay live for 60 days, giving you maximum visibility and helping you connect with the right buyers. Want to keep your ad online? Simply republish it after 60 days and continue reaching your audience!"
      />
      {/* </Slider> */}

      {bannerProducts?.length > 0 && (
        <HomeLandingHero isLoading={isLoading} products={memoizedProducts.bannerProducts} />
      )}

      <HomeCategories categoryCounts={categoryCounts} />

      {memoizedProducts.featuredProducts?.length > 0 && (
        <ProductList title="Featured" products={memoizedProducts.featuredProducts} />
      )}

      {memoizedProducts.newArrivalsProducts?.length > 0 && (
        <ProductList title="New Arrivals" products={memoizedProducts.newArrivalsProducts} />
      )}

      {memoizedProducts.nearestProducts?.length > 0 && (
        <ProductList title="Near You" products={memoizedProducts.nearestProducts} />
      )}

      {/* <PollCompLazy /> */}
    </>
  );
}
